* {
    margin: 0;
    padding: 0;
  }
  
  body, html {
    width: 100%;
    height: 100vh;
    background-color: black;
  }
  
  .wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: auto; */
  }
  .wrapper h1.glitch {
    color: white;
    font-family: sans-serif;
    font-weight: 800;
    position: relative;
    font-size: 100px;
    padding: 30px;
  }
  .wrapper h1.glitch::before, .wrapper h1.glitch::after {
    padding: 30px;
    color: white;
    content: "hi.";
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    overflow: hidden;
    top: 0;
  }
  .wrapper h1.glitch::before {
    left: 3px;
    text-shadow: -2px 0 red;
    animation-name: glitch-animation-1;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: reverse-alternate;
  }
  .wrapper h1.glitch::after {
    left: -3px;
    text-shadow: -2px 0 blue;
    animation-name: glitch-animation-2;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: reverse-alternate;
  }
  
  @keyframes glitch-animation-1 {
    0% {
      clip: rect(139px, 350px, 113px, 30px);
    }
    5% {
      clip: rect(150px, 350px, 101px, 30px);
    }
    10% {
      clip: rect(103px, 350px, 9px, 30px);
    }
    15% {
      clip: rect(105px, 350px, 48px, 30px);
    }
    20% {
      clip: rect(123px, 350px, 107px, 30px);
    }
    25% {
      clip: rect(144px, 350px, 77px, 30px);
    }
    30% {
      clip: rect(113px, 350px, 42px, 30px);
    }
    35% {
      clip: rect(112px, 350px, 107px, 30px);
    }
    40% {
      clip: rect(12px, 350px, 131px, 30px);
    }
    45% {
      clip: rect(101px, 350px, 55px, 30px);
    }
    50% {
      clip: rect(54px, 350px, 135px, 30px);
    }
    55% {
      clip: rect(50px, 350px, 144px, 30px);
    }
    60% {
      clip: rect(18px, 350px, 99px, 30px);
    }
    65% {
      clip: rect(68px, 350px, 29px, 30px);
    }
    70% {
      clip: rect(127px, 350px, 8px, 30px);
    }
    75% {
      clip: rect(142px, 350px, 26px, 30px);
    }
    80% {
      clip: rect(41px, 350px, 33px, 30px);
    }
    85% {
      clip: rect(22px, 350px, 11px, 30px);
    }
    90% {
      clip: rect(101px, 350px, 8px, 30px);
    }
    95% {
      clip: rect(16px, 350px, 86px, 30px);
    }
    100% {
      clip: rect(105px, 350px, 43px, 30px);
    }
  }
  @keyframes glitch-animation-2 {
    0% {
      clip: rect(90px, 350px, 94px, 30px);
    }
    5% {
      clip: rect(42px, 350px, 92px, 30px);
    }
    10% {
      clip: rect(43px, 350px, 41px, 30px);
    }
    15% {
      clip: rect(6px, 350px, 1px, 30px);
    }
    20% {
      clip: rect(16px, 350px, 2px, 30px);
    }
    25% {
      clip: rect(149px, 350px, 29px, 30px);
    }
    30% {
      clip: rect(54px, 350px, 84px, 30px);
    }
    35% {
      clip: rect(89px, 350px, 123px, 30px);
    }
    40% {
      clip: rect(59px, 350px, 8px, 30px);
    }
    45% {
      clip: rect(123px, 350px, 28px, 30px);
    }
    50% {
      clip: rect(24px, 350px, 49px, 30px);
    }
    55% {
      clip: rect(97px, 350px, 76px, 30px);
    }
    60% {
      clip: rect(110px, 350px, 88px, 30px);
    }
    65% {
      clip: rect(149px, 350px, 80px, 30px);
    }
    70% {
      clip: rect(84px, 350px, 131px, 30px);
    }
    75% {
      clip: rect(45px, 350px, 42px, 30px);
    }
    80% {
      clip: rect(141px, 350px, 109px, 30px);
    }
    85% {
      clip: rect(68px, 350px, 77px, 30px);
    }
    90% {
      clip: rect(63px, 350px, 11px, 30px);
    }
    95% {
      clip: rect(42px, 350px, 27px, 30px);
    }
    100% {
      clip: rect(107px, 350px, 147px, 30px);
    }
  }